var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getWithoutPrompt } from './getWithoutPrompt';
import { renewTokensWithRefresh } from './renewTokensWithRefresh';
import { getDefaultTokenParams } from './util';
// If we have a refresh token, renew using that, otherwise getWithoutPrompt
export function renewTokens(sdk, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const tokens = sdk.tokenManager.getTokensSync();
        if (tokens.refreshToken) {
            return renewTokensWithRefresh(sdk, options, tokens.refreshToken);
        }
        // Get tokens using the SSO cookie
        options = Object.assign({
            scopes: sdk.options.scopes,
            authorizeUrl: sdk.options.authorizeUrl,
            userinfoUrl: sdk.options.userinfoUrl,
            issuer: sdk.options.issuer
        }, options);
        if (sdk.options.pkce) {
            options.responseType = 'code';
        }
        else {
            const { responseType } = getDefaultTokenParams(sdk);
            options.responseType = responseType;
        }
        return getWithoutPrompt(sdk, options)
            .then(res => res.tokens);
    });
}
